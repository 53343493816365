import React from 'react'
// import ReactDOM from 'react-dom'
import { hydrate, render } from "react-dom"
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {createStore, applyMiddleware, compose} from 'redux'
import rootReducer from './store/reducers/rootReducer'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import firebase from 'firebase/app'
import {ReactReduxFirebaseProvider, getFirebase} from 'react-redux-firebase'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/analytics'
import Rebase from 're-base'

const firebaseConfig = {
    apiKey: "AIzaSyBrYOPAKkbpOhXdVgg_qIt5_EATkPXDIxM",
    authDomain: "whatsuptexasapp-9062b.firebaseapp.com",
    databaseURL: "https://whatsuptexasapp-9062b.firebaseio.com",
    projectId: "whatsuptexasapp-9062b",
    storageBucket: "whatsuptexasapp-9062b.appspot.com",
    messagingSenderId: "482412273172",
    appId: "1:482412273172:web:42776bf61d18e0d2c69101",
    measurementId: "G-PH1VMKGHPF"
  }
  // Initialize Firebase
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const base = Rebase.createClass(firebase.database())
  const firebaseStorage = firebase.storage()
  const firebaseAnalytics = firebase.analytics()

  const store = createStore(rootReducer,
    compose(
        applyMiddleware(thunk.withExtraArgument({getFirebase}))
    )
)

const rrfProps = {
    firebase,
    config: {
      
    },
    dispatch: store.dispatch
  }

  export {base, firebaseApp, firebaseStorage, firebaseAnalytics}

  const rootElement = document.getElementById("root")
    if (rootElement.hasChildNodes()) {
      hydrate(<Provider store={store}><ReactReduxFirebaseProvider{...rrfProps}><App />
        </ReactReduxFirebaseProvider></Provider>, rootElement)
    } else {
      render(<Provider store={store}><ReactReduxFirebaseProvider{...rrfProps}><App />
        </ReactReduxFirebaseProvider></Provider>, rootElement)
    }

  // const rootElement = document.getElementById("root")
  //   if (rootElement.hasChildNodes()) {
  //     hydrate(<App />, rootElement)
  //   } else {
  //     render(<App />, rootElement)
  //   }

  // ReactDOM.render(<Provider store={store}><ReactReduxFirebaseProvider{...rrfProps}><App />
  //   </ReactReduxFirebaseProvider></Provider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister()

