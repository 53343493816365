import React from 'react'
import {connect} from 'react-redux'
import {signOut} from '../../store/actions/authActions'
import {Link} from 'react-router-dom'
import './SignedInLinks.css'


const SignedInLinks = (props) => {
    return (
      <div className="SignedInLinks">
        <p>
          <Link to='/admin'>Admin</Link>
        </p>
        <p>
          <Link to='/' onClick={props.signOut}>Log Out</Link>
        </p>
      </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      signOut: () => dispatch(signOut())
    }
  }

export default connect(null, mapDispatchToProps)(SignedInLinks)