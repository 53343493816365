import React from 'react'
import './BusinessCard.css'

const BusinessSummary = ({business}) => {

    return (
        <div className="BusinessCard">
            <img src={business.value.Logo} alt="business logo"/>
                <div className="BusinessCardFooter">
                    <h3>{business.value.Name}</h3>
                    <h5>{business.value.Offer}</h5>
                </div>
        </div>
    )
}

export default BusinessSummary