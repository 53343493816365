import React, { Component } from 'react';
import {Redirect} from 'react-router-dom'
import { firebaseApp } from '../index'
import {Link} from 'react-router-dom'
import './forgotPassword.css'

class ForgotPassword extends Component {

    _isMounted = false
    errorText = 'auth/user-not-found'

    constructor() {
        super()
        this.state = {
            loading: true,
            redirect: false,
            email: null,
            emailError: null,
            emailSent: false
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        const myThis = this
        e.preventDefault();
        this.setState({
            loading: true
        })
        if (this.state.email) {
            if (this._isMounted) {
                firebaseApp.auth().sendPasswordResetEmail(this.state.email).then(function() {
                    // Email sent
                    myThis.setState({
                        emailSent: true,
                        loading: false
                    })
                }).catch(function(error) {
                    if (error.code === myThis.errorText) {
                        myThis.setState({
                            emailError: 'The email address does not match our records.  Please enter a different email address.',
                            loading: false
                        })
                    } else {
                        myThis.setState({
                            emailError: 'There was an error resetting your password.  Please contact us for help.',
                            loading: false
                        })
                    }
                })
            }
        } else {
            if (this._isMounted) {
                this.setState({
                    emailError: 'Please enter your email address...',
                    loading: false
                })
            }
        }
    }

    componentDidMount () {

        this._isMounted = true
        const my = this

        firebaseApp.auth().onAuthStateChanged(function(user) {
            if (user) {
              // User is signed in redirect to homepage
              if (my._isMounted) {
                my.setState({ redirect: true })
                my.setState({ loading: false })
              }
            } else {
                if (my._isMounted) {
                    my.setState({ loading: false })
                }
            }
        })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {

        if (this.state.loading !== true) {
    
            if (this.state.redirect === true) {
                return <Redirect to='/'/>
            }

            if (this.state.emailSent === true) {
                return (
                    <div className="ForgotPasswordBackground">
                        <div className="ForgotPasswordBannerBar">
                        </div>
                        <div className="ForgotPasswordSpinnerContainer">
                            <div className="ForgotPasswordEmailSentContainer">
                                <p>Please Check Your Email</p>
                                <Link to='/login'>
                                    <button className="btn grey darken-4">OK</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            }

            return (
                <div className="ForgotPasswordBackground">
                    <div className="ForgotPasswordBannerBar">
                    </div>
                    <div className="ForgotPasswordContainer">
                        <div className="ForgotPassword">
                            <h1>Forgot Password</h1>
                            <form onSubmit={this.handleSubmit} className="Stuff">
                                <div className="input-field">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" onChange={this.handleChange}/>
                                </div>
                                <div className="ForgotPasswordResetInstructions">
                                    <p>We’ll send you an email to reset your password.</p>
                                </div>
                                <div className="input-field">
                                    <button className="btn grey darken-4">Reset Password</button>
                                    <div className="red-text">
                                        {this.state.emailError ? <p>{this.state.emailError}</p> : null}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="ForgotPasswordBackground">
                    <div className="ForgotPasswordBannerBar">
                    </div>
                    <div className="ForgotPasswordSpinnerContainer">
                        <div className="preloader-wrapper active">
                            <div className="spinner-layer spinner-blue-only">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div><div className="gap-patch">
                                <div className="circle"></div>
                            </div><div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default ForgotPassword