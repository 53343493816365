import React from 'react'
import './AdminAddEventImageView.css'

const AdminAddEventImageView = (props) => {
    const imageUrl = props.imageUrl

    if (imageUrl !== null && imageUrl !== 'None' && imageUrl !== 'none') {
        return (
            <div className="AdminAddEventImageView">
                <img src={imageUrl} alt="event image1"/>
            </div>
        )
    } else {
        return (
            <div className="AdminAddEventImageView">
            </div>
        )
    }
}

export default AdminAddEventImageView