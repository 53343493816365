import React from 'react'
import './exclusive.css'

function Exclusive() {
    return (
        <div className="ExclusiveBackground">
            <div className="ExclusiveBannerBar">
            </div>
            <div className="ExclusiveDetailContainer">
                <div className="ExclusiveDetail">
                    <h1>Be Exclusive</h1>
                    <p>Updated 3/20/2019</p>
                    <h5>What's Up Texas? - Be Exclusive</h5>
                    <p>
                    We develop loyalty to What's Up Texas? business sponsors by limiting the number 
                    of businesses that provide a particular type of product or service in a hometown.  
                    We feel this is not only fair to other sponsors, but also rewards early adopters.
                    </p>
                    <h5>Restaurant</h5>
                    <div className="ExclusiveList">
                        <p>Pizza</p>
                        <p>Italian</p>
                        <p>Mexican</p>
                        <p>Comfort</p>
                        <p>Burgers</p>
                        <p>Seafood</p>
                        <p>BBQ</p>
                        <p>Sushi</p>
                        <p>Chinese</p>
                        <p>Thai</p>
                        <p>Indian</p>
                        <p>Fusion</p>
                        <p>Greek</p>
                        <p>German</p>
                        <p>Pub</p>
                        <p>Hibachi</p>
                        <p>Soup / Salad / Sandwich</p>
                        <p>American</p>
                        <p>Steak</p>
                    </div>
                    <h5>Service</h5>
                    <div className="ExclusiveList">
                        <p>Dry Cleaning</p>
                        <p>Roofing</p>
                        <p>Carpet Cleaning</p>
                        <p>Handy Man</p>
                        <p>Yard / Landscape</p>
                        <p>Painter</p>
                        <p>Pest Control</p>
                        <p>Music Lessons</p>
                        <p>Photographer</p>
                        <p>Embroidery / Screen Printing</p>
                        <p>Insurance</p>
                        <p>Veterinarian</p>
                        <p>Pet Boarding</p>
                        <p>Car Wash</p>
                        <p>Realtor</p>
                        <p>Home Security</p>
                        <p>Car Dealership - Toyota</p>
                        <p>Car Dealership - Honda</p>
                        <p>Car Dealership - Ford</p>
                        <p>Car Dealership - Chevrolet</p>
                        <p>Car Dealership - GM</p>
                        <p>Car Dealership - Nissan</p>
                        <p>Car Dealership - Hyundai</p>
                        <p>Pool Cleaning / Repair</p>
                        <p>House Cleaning</p>
                        <p>Window Cleaning</p>
                        <p>Pet Grooming</p>
                        <p>Holiday Lighting</p>
                    </div>
                    <h5>Retail</h5>
                    <div className="ExclusiveList">
                        <p>Ladies’ Apparel</p>
                        <p>Florist</p>
                        <p>Bike Shop</p>
                        <p>Running</p>
                        <p>Toys</p>
                        <p>Furniture</p>
                        <p>Hardware</p>
                        <p>Garden</p>
                        <p>Sporting Goods</p>
                        <p>Pet Supplies</p>
                        <p>Pool Supplies</p>
                        <p>Lighting</p>
                    </div>
                    <h5>Health and Wellness</h5>
                    <div className="ExclusiveList">
                        <p>Hair - Salon Stylist</p>
                        <p>Hair - Barbershop</p>
                        <p>Nail Salon</p>
                        <p>Spa</p>
                        <p>Waxing</p>
                        <p>Lashes</p>
                        <p>Cryotherapy</p>
                        <p>Fitness</p>
                        <p>Dentist</p>
                        <p>Optometry</p>
                        <p>Medical Spa</p>
                        <p>Self Defense</p>
                        <p>Chiropractor</p>
                        <p>Orthodontist</p>
                        <p>Yoga / Pilates</p>
                        <p>Health Food</p>
                        <p>Juice Bar</p>
                    </div>
                    <h5>Sweets and Treats</h5>
                    <div className="ExclusiveList">
                        <p>Frozen Yogurt</p>
                        <p>Smoothies</p>
                        <p>Cupcakes</p>
                        <p>Ice Cream</p>
                        <p>Donuts</p>
                        <p>Coffee / Tea</p>
                        <p>Bakery</p>
                        <p>Candy</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Exclusive