import React from 'react'
import './notFound.css'
import { NavLink } from 'react-router-dom'

function NotFound() {
    return (
        <div className="NotFoundBackground">
            <div className="NotFoundBannerBar">
            </div>
            <div className="NotFoundDetailContainer">
                <div className="NotFoundDetail">
                    <h1>Page Not Available</h1>
                    <p>
                    Oops!  It looks like the page that you're looking for doesn't exist.<br/><br/><br/>
                    <NavLink to='/'>Visit Our Homepage</NavLink>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NotFound