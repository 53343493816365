import React from 'react'
import './EventDetailsImageView.css'

const EventDetailsImageView = (props) => {
    const imageUrl = props.imageUrl

    if (imageUrl && imageUrl !== 'None' && imageUrl !== 'none') {
        return (
            <div className="EventDetailsImageView">
                <img src={imageUrl} alt="event image1"/>
            </div>
        )
    } else {
        return (
            <div className="EventDetailsImageView">
            </div>
        )
    }
}

export default EventDetailsImageView