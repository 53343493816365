import React from 'react'
import './EventDetails.css'

const EventDetailsLinkView = (props) => {
    const linkUrl = props.linkUrl
    const webPreFix = 'http://'

    if (linkUrl && linkUrl !== 'None' && linkUrl !== 'none') {
        return (
            <a href={webPreFix+linkUrl} target="_blank" rel="noopener noreferrer">
              <div className="EventDetailsLearnMoreContainer">
                  <div className="LearnMore">
                      <h3>Learn More</h3>
                      <p>{linkUrl}</p>
                  </div>
                  <img src={require('../../images/icon-web-blue.svg')} alt="web icon"/>
              </div>
            </a>
        )
    } else {
        return (
            <div className="EventDetailsLearnMoreContainer">
            </div>
        )
    }
}

export default EventDetailsLinkView