import React from 'react'
import AdminEventSummary from './AdminEventSummary'

const AdminEventList = (props) => {

    const events = Object.keys(props.events)
    const adminName = props.adminName
    const businessAdmin = props.businessAdmin
    const calendarAdmin = props.calendarAdmin
    const cityAdmin = props.cityAdmin
    const passAdmin = props.passAdmin
    
    return (
        <div className="EventsGroup">
            {events.map(event => {
                return (
                    <div key={event}>
                        <AdminEventSummary value={props.events[event]} key={event} adminName={adminName} businessAdmin={businessAdmin} 
                                        cityAdmin={cityAdmin} passAdmin={passAdmin} calendarAdmin={calendarAdmin} />
                    </div>
                )
            })}
        </div>
    )
}

export default AdminEventList