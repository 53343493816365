import React, {Component} from 'react'
import Businesses from '../components/businesses/Businesses'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { firebaseAnalytics } from '../index'

class Home extends Component {

  componentDidMount () {
    const analytics = firebaseAnalytics
    analytics.logEvent('home_page_viewed')

    analytics.logEvent('web_page_viewed', {
      web_page_title: 'Home'
    })
  }

    render() {
      const {businesses} = this.props

      return (
        <div className="HomeContainer">
          <Helmet>
            <meta name="apple-itunes-app" content="app-id=1346208825"/>
            <title>Home | What's Up Texas?</title>
            <meta name="description" content="Save money, support local businesses, and keep up with what's happening around town." />
            <meta property="og:title" content="Home | What's Up Texas?" />
            <meta property="og:description" content="Save money, support local businesses, and keep up with what's happening around town." />
            <meta property="og:image" content="https://whatsuptexas.com/logo512.png" />
            <meta property="twitter:title" content="Home | What's Up Texas?" />
            <meta property="twitter:description" content="Save money, support local businesses, and keep up with what's happening around town." />
            <meta property="twitter:image" content="https://whatsuptexas.com/logo512.png" />
          </Helmet>
          <div className="Hero">
            <div className="HeroGroup">
              <img className="HeroGroupImage" src={require('../images/wut_banner2.svg')} alt="whats up texas logo"/>
              <p>Save money, support local businesses, and keep up with what's happening around town.</p>
              <div className="BadgeGroup">
              <a className="AppStoreBadgeLink" href='https://itunes.apple.com/us/app/whats-up-texas/id1346208825?mt=8' target="_blank" rel="noopener noreferrer">
                <img className="AppStoreBadge" src={require('../images/App_Store_Badge.svg')} alt="app store badge"/>                    
              </a>
              <a className="GooglePlayBadgeLink" href='https://play.google.com/store/apps/details?id=com.whatsuptexas.whatsuptexas' target="_blank" rel="noopener noreferrer">
                <img className="GooglePlayBadge" src={require('../images/Google_Play_Badge.svg')} alt="google play store badge"/>                 
              </a>
              </div>
            </div>
          </div>
          <div className="CalendarGroup">
            <h1>Community Calendars</h1>
            <div className="CalendarGroupContainer">
              {/* <Link to='/events/alliance'>
                <div className="CalendarCard">
                  <img src={require('../images/Alliance_Calendar.jpg')} alt="alliance calendar"/>
                    <div className="CalendarCardFooter">
                        <h3>Alliance, TX</h3>
                    </div>
                </div>
              </Link> */}
              <Link to='/events/keller'>
                <div className="CalendarCard">
                  <img src={require('../images/Keller_Calendar.jpg')} alt="keller calendar"/>
                    <div className="CalendarCardFooter">
                        <h3>Keller, TX</h3>
                    </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="BusinessGroup">
            <h1>Participating Businesses</h1>
            <Businesses businesses={businesses}/>
          </div>
        </div> 
      )
    }
  }

export default Home