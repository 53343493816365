import React from 'react'
import EventSummary from './EventSummary'

const EventList = (props) => {

    const events = Object.keys(props.events)
    const city = props.city
    
    return (
        <div className="EventsGroup">
            {events.map(event => {
                return (
                    <div key={event}>
                        <EventSummary value={props.events[event]} key={event} city={city}/>
                    </div>
                )
            })}
        </div>
    )
}

export default EventList