import React, { Component } from 'react';
import {connect} from 'react-redux'
import {signIn} from '../../store/actions/authActions'
import {Redirect} from 'react-router-dom'
import './SignIn.css'

class SignIn extends Component {

    state = {
        email: '',
        password: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.signIn(this.state)
    }

    render() {

        const {authError, auth} = this.props

        if (auth.uid) {
            return <Redirect to='/admin' />
        } else {
            return (
                <div className="SignInBackground">
                    <div className="SignInBannerBar">
                    </div>
                    <div className="SignInContainer">
                        <div className="SignIn">
                            <h1>Sponsor Login</h1>
                            <form onSubmit={this.handleSubmit} className="Stuff">
                                <div className="input-field">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" onChange={this.handleChange}/>
                                </div>
                                <div className="input-field">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" id="password" onChange={this.handleChange}/>
                                </div>
                                <div className="input-field">
                                    <button className="btn grey darken-4">Login</button>
                                    <div className="red-text">
                                        {authError ? <p>{authError}</p> : null}
                                    </div>
                                </div>
                                <a href="/forgot-password" className="btn-flat">Forgot Password?</a>
                            </form>
                        </div>
                    </div>
                </div>
            )
        } 
    }
}

const mapStateToProps = (state) => {
    return {
      authError: state.auth.authError,
      auth: state.firebase.auth
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
      signIn: (creds) => dispatch(signIn(creds))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
