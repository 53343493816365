import React from 'react'
import { NavLink } from 'react-router-dom'
import './FooterLinks.css'

const FooterLinks = () => {
  return (
    <div>
      <div className="FooterLinks">
        <p><NavLink to='/about'>About</NavLink></p>
        <p><NavLink to='/pass-holder-terms'>Terms</NavLink></p>
        <p><NavLink to='/sponsor-terms-of-service'>Sponsor Terms</NavLink></p>
        <p><NavLink to='/login'>Sponsor Login</NavLink></p>
        <a href="mailto:info@whatsuptexas.com">Contact</a>
      </div>
    </div>
  )
}

export default FooterLinks