import React from 'react'
import {Link} from 'react-router-dom'
import SignedInLinks from './SignedInLinks'
import {connect} from 'react-redux'
import './Navbar.css'

class Navbar extends React.Component {

    constructor (props) {
        super(props)

        this.state = {
            hasScrolled: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll',
        this.handleScroll)
    }

    handleScroll = (event) => {
        const scrollTop = window.pageYOffset

        if (scrollTop > 50) {
            this.setState({hasScrolled: true})
        } else {
            this.setState({hasScrolled: false})
        }
    }
    
    render() {
        // could use authenticated prop from Navbar
        const {auth} = this.props
        const links = auth.uid ? <SignedInLinks /> : null

        return (
            <div className={this.state.hasScrolled ? 'Navbar NavbarScrolled' : 'Navbar'}>
                <div className="NavbarGroup">
                    <Link to='/' className="NavbarLogo"><img src={require('../../images/wut7.svg')} alt="What's Up Texas? Logo"/></Link>
                        <div className="NavbarLinksGroup">
                            {auth.isLoaded && 
                                links
                                }
                        </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    
    return {
        auth: state.firebase.auth
    }
  }

export default connect(mapStateToProps)(Navbar)