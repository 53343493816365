import React from 'react'
import BusinessSummary from './BusinessSummary'
import {Link} from 'react-router-dom'

const BusinessList = ({businesses}) => {
    return (
        <div className="CardGroup">
            {businesses && businesses.map(business => {
                    return (
                        <Link to={'/businesses/' + business.key} key={business.key}>
                        <BusinessSummary business={business} />
                        </Link>
                    )
            })}
        </div>
    )
}

export default BusinessList