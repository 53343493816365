import React from 'react'
import './terms.css'

function Terms() {
    return (
        <div className="TermsBackground">
            <div className="TermsBannerBar">
            </div>
            <div className="TermsDetailContainer">
                <div className="TermsDetail">
                    <h1>Pass Holder Terms</h1>
                    <h3>Updated 2/14/2018</h3>
                    <p>
                    The purpose of the What's Up Texas? mobile app and What's Up? loyalty passes 
                    (for mobile wallets) is to connect individuals that love their hometown with 
                    businesses that want to reward their loyalty.  Participating businesses provide 
                    app users with a discount each and every time they present the app.<br/><br/>

                    By signing up for the mobile app and (or) subscribing to receive a What's Up? 
                    loyalty pass (for your mobile wallet) you agree to receive email marketing and 
                    app notifications from What's Up Texas? on behalf of the businesses that are 
                    participating in the loyalty pass program.  In the event that you no longer wish 
                    to receive email marketing from What's Up Texas?, you may unsubscribe from our 
                    email marketing list at any time.<br/><br/>

                    Businesses participating in the loyalty pass program have the right to cancel 
                    their participation at any time.  What's Up Texas? also reserves the right to 
                    cancel the loyalty pass program at any time.  In theory, this should get local 
                    businesses repeat customers as well as allowing loyal customers to save some moolah.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Terms