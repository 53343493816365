import React from 'react'
import './AdminAddEvent.css'
import { firebaseApp, firebaseStorage, base } from '../../index'
import NotFound from '../../pages/notFound'
import {Redirect} from 'react-router-dom'
import 'flatpickr/dist/themes/material_green.css'
import Flatpickr from 'react-flatpickr'
import AdminAddEventImageView from './AdminAddEventImageView'
import { TextInput } from 'react-materialize'
import validator from 'validator'
import {Link} from 'react-router-dom'
import imageCompression from 'browser-image-compression'


class AdminAddEvent extends React.Component {

    _isMounted = false
    eventLink = null
    eventLinkLowerCase = null
    webURL = null
    eventID = null
    userId = null
    eventStartDateTime = null
    eventEndDateTime = null

    constructor() {
        super()
        this.state = {
            loading: true,
            redirect: false,
            adminName: null,
            adminType: null,
            hostName: null,
            hostLogo: null,
            hostCalendarOne: null,
            hostCalendarTwo: null,
            hostCalendarThree: null,
            hostCalendarFour: null,
            authenticatedAdminMatch: false,
            synced: false,
            eventName: null,
            venueName: null,
            addressTwo: null,
            addressThree: null,
            eventStart: new Date(),
            eventEnd: new Date(),
            link: null,
            eventImage: null,
            eventImageUrl: null,
            cancelEvent: 'False',
            formError: null,
            eventAdded: false,
            event: {},
            isDuplicate: false,
            imageUpdated: false,
            isEdit: false
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {

        this.setState({
            loading: true
        })

        this.eventStartDateTime = Date.parse(this.state.eventStart)/1000
        this.eventEndDateTime = Date.parse(this.state.eventEnd)/1000
        e.preventDefault()

        if (this.state.eventName && this.state.venueName && this.state.addressTwo && this.state.addressThree
            && this.state.eventStart && this.state.eventEnd) {
                if (this.state.eventName.length > 30) {
                    this.setState({
                        formError: 'Event Name cannot exceed 30 characters...',
                        loading: false
                    })
                } else {
                    // check eventEnd > eventStart
                    if (this.eventEndDateTime <= this.eventStartDateTime) {
                        this.setState({
                            formError: 'Event End must be after Event Start...',
                            loading: false
                        })
                    } else {
                        if (this.state.hostName && this.state.hostLogo && this.state.hostCalendarOne) {
                            if (this.state.link && this.state.link !== 'None' && this.state.link !== 'none' && this.state.link !== '') {
                                // check link formatting
                                if (validator.isURL(this.state.link)) {

                                    this.eventLinkLowerCase = this.state.link.toLowerCase()
                                    const urlLessHttp = this.eventLinkLowerCase.replace("http://", "")
                                    const urlLessHttps = this.eventLinkLowerCase.replace("https://", "")

                                    if (this.eventLinkLowerCase === urlLessHttp && this.eventLinkLowerCase === urlLessHttps) {
                                        this.webURL = this.eventLinkLowerCase
                                    } else {
                                        if (this.eventLinkLowerCase !== urlLessHttp) {
                                            this.webURL = urlLessHttp
                                        } else {
                                            if (this.eventLinkLowerCase !== urlLessHttps) {
                                                this.webURL = urlLessHttps
                                            } else {
                                                this.webURL = 'None'
                                            }
                                        }
                                    }
                                    // Does image exist
                                    if (this.state.eventImage && this.state.eventImage !== 'None' && this.state.eventImage !== 'none') {
                                        // add image to storage get link and add event
                                        this.addImage()
                                    } else {
                                        // eventImage does not exist
                                        this.addEvent(this.state.hostName, this.userId, this.state.hostLogo, this.state.eventName,
                                            this.eventStartDateTime, this.eventEndDateTime, this.webURL, 'None', this.state.venueName,
                                            this.state.addressTwo, this.state.addressThree, this.state.cancelEvent)
                                    }
                                } else {
                                    this.setState({
                                        formError: 'Please check the formatting of your event link...',
                                        loading: false
                                    })
                                }
                            } else {
                                // webURL does not exist
                                this.webURL = 'None'
                                // Does image exist
                                if (this.state.eventImage && this.state.eventImage !== 'None' && this.state.eventImage !== 'none') {
                                    // add image to storage get link and add event
                                    this.addImage()
                                } else {
                                    // eventImage does not exist
                                    this.addEvent(this.state.hostName, this.userId, this.state.hostLogo, this.state.eventName,
                                        this.eventStartDateTime, this.eventEndDateTime, this.webURL, 'None', this.state.venueName,
                                        this.state.addressTwo, this.state.addressThree, this.state.cancelEvent)
                                }
                            }
                        } else {
                            this.setState({
                                formError: 'Unable to add event, please try again later...',
                                loading: false
                            })
                        }
                    }
                }
            } else {
                this.setState({
                    formError: 'Please complete required fields...',
                    loading: false
                })
            }
    }

    addImage() {

        const storageRef = firebaseStorage.ref()
        const moment = require('moment')
        const eventMonth = moment.unix(this.eventStartDateTime).format('M')
        const eventYear = moment.unix(this.eventStartDateTime).format('YYYY')
        const adminName = this.state.adminName
        const imageName = this.state.eventImage.name
        const my = this

        const options = {
            maxSizeMB: 0.2,
          }

          // if duplicate and image not updated

        if (this.state.isDuplicate === true && this.state.imageUpdated === false) {
            my.addEvent(my.state.hostName, my.userId, my.state.hostLogo, my.state.eventName,
                my.eventStartDateTime, my.eventEndDateTime, my.webURL, my.state.eventImage, my.state.venueName,
                my.state.addressTwo, my.state.addressThree, my.state.cancelEvent)
        } else {
            imageCompression(this.state.eventImage, options)
            .then(function (compressedFile) {
                const uploadRef = storageRef.child("Calendar_Images").child(eventYear).child(eventMonth).child(adminName).child(imageName)

                uploadRef.put(compressedFile).then(function() {
                    uploadRef.getDownloadURL().then(function(downloadURL) {
                        my.addEvent(my.state.hostName, my.userId, my.state.hostLogo, my.state.eventName,
                            my.eventStartDateTime, my.eventEndDateTime, my.webURL, downloadURL, my.state.venueName,
                            my.state.addressTwo, my.state.addressThree, my.state.cancelEvent)
                          })
                  }) 
            })
            .catch(function (error) {
                console.log(error.message)
            })
        }
    }

    

    addEvent(hostName, userId, hostLogo, eventName, eventStart, eventEnd, webLink, eventImage, venueName, addressTwo, addressThree, cancelEvent) {

        const myThis = this

        var postData = {
            BusinessName: hostName,
            UserId: userId,
            Logo: hostLogo,
            EventName: eventName,
            EventStart: eventStart,
            EventStop: eventEnd,
            EventLink: webLink,
            EventImage: eventImage,
            Address1: venueName,
            Address2: addressTwo,
            Address3: addressThree,
            CancelEvent: cancelEvent
        }

        // if edit then use existing eventID

        if (this.state.isEdit !== true) {
            this.eventID = firebaseApp.database().ref().child('AdminEvents').child(this.state.adminName).push().key
        }

        var updates = {}
        updates[`/AdminEvents/${this.state.adminName}/` + this.eventID] = postData

        if (this.state.hostCalendarFour && this.state.hostCalendarFour !== 'None' && this.state.hostCalendarFour !== 'none') {
            updates[`/Events/${this.state.hostCalendarFour}/` + this.eventID] = postData
            updates[`/Events/${this.state.hostCalendarThree}/` + this.eventID] = postData
            updates[`/Events/${this.state.hostCalendarTwo}/` + this.eventID] = postData
            updates[`/Events/${this.state.hostCalendarOne}/` + this.eventID] = postData
            return firebaseApp.database().ref().update(updates,
                function(error) {
                if (error) {
                    myThis.setState({
                        formError: 'Unable to add event, please try again later...',
                        loading: false
                    })
                } else {
                    // event added successfully
                    myThis.setState({
                        eventAdded: true,
                        loading: false
                    })
                }
              })
        } else {
            if (this.state.hostCalendarThree && this.state.hostCalendarThree !== 'None' && this.state.hostCalendarThree !== 'none') {
                updates[`/Events/${this.state.hostCalendarThree}/` + this.eventID] = postData
                updates[`/Events/${this.state.hostCalendarTwo}/` + this.eventID] = postData
                updates[`/Events/${this.state.hostCalendarOne}/` + this.eventID] = postData
                return firebaseApp.database().ref().update(updates,
                    function(error) {
                    if (error) {
                        myThis.setState({
                            formError: 'Unable to add event, please try again later...',
                            loading: false
                        })
                    } else {
                        // event added successfully
                        myThis.setState({
                            eventAdded: true,
                            loading: false
                        })
                    }
                  })
            } else {
                if (this.state.hostCalendarTwo && this.state.hostCalendarTwo !== 'None' && this.state.hostCalendarTwo !== 'none') {
                    updates[`/Events/${this.state.hostCalendarTwo}/` + this.eventID] = postData
                    updates[`/Events/${this.state.hostCalendarOne}/` + this.eventID] = postData
                    return firebaseApp.database().ref().update(updates,
                        function(error) {
                        if (error) {
                            myThis.setState({
                                formError: 'Unable to add event, please try again later...',
                                loading: false
                            })
                        } else {
                            // event added successfully
                            myThis.setState({
                                eventAdded: true,
                                loading: false
                            })
                        }
                      })
                } else {
                    updates[`/Events/${this.state.hostCalendarOne}/` + this.eventID] = postData
                    return firebaseApp.database().ref().update(updates,
                        function(error) {
                        if (error) {
                            myThis.setState({
                                formError: 'Unable to add event, please try again later...',
                                loading: false
                            })
                        } else {
                          // event added successfully
                          myThis.setState({
                            eventAdded: true,
                            loading: false
                        })
                        }
                      })
                }
            }
        }
    }

    fileSelectedHandler = event => {
        this.setState({
            eventImage: event.target.files[0],
            eventImageUrl: URL.createObjectURL(event.target.files[0]),
            imageUpdated: true
        })
    }

    componentDidMount(){

        const routeAdminName = this.props.match.params.adminName
        const routeEventId = this.props.match.params.id

        const path = this.props.match.path
        const subPath = path.substring(1,5)

        if (subPath === 'edit') {
            this.eventID = routeEventId
            this.setState({
                isEdit: true
            })
        }

        this._isMounted = true
        const my = this

        firebaseApp.auth().onAuthStateChanged(function(user) {
            if (user) {
              // User is signed in
              my.userId = user.uid
              if (my._isMounted) {
                fetchUserData(user)
              }
            } else {
              // User is signed out
              if (my._isMounted) {
                my.setState({ redirect: true })
                my.setState({ loading: false })
              }
            }
        })

        async function fetchUserData (user) {

            const userId = user.uid
            const snapshot = await firebaseApp.database().ref('/Users/' + userId).once('value')

            if (snapshot.val().Admin) {
                if (snapshot.val().Admin !== 'None') {
                    if (my._isMounted) {
                    my.setState({adminName: snapshot.val().Admin})
                    my.setState({adminType: 'Admin'})
                    }
                }
            }
            if (snapshot.val().CalendarAdmin) {
                if (snapshot.val().CalendarAdmin !== 'None') {
                    if (my._isMounted) {
                    my.setState({adminName: snapshot.val().CalendarAdmin})
                    my.setState({adminType: 'CalendarAdmin'})
                    }
                }
            }
            if (snapshot.val().CityAdmin) {
                if (snapshot.val().CityAdmin !== 'None') {
                    if (my._isMounted) {
                    my.setState({adminName: snapshot.val().CityAdmin})
                    my.setState({adminType: 'CityAdmin'})
                    }
                }
            }
            if (snapshot.val().PassAdmin) {
                if (snapshot.val().PassAdmin !== 'None') {
                    if (my._isMounted) {
                    my.setState({adminName: snapshot.val().PassAdmin})
                    my.setState({adminType: 'PassAdmin'})
                    }
                }
            }

            // verify adminNames match

            if (my.state.adminName === routeAdminName) {
                my.setState({ authenticatedAdminMatch: true })

                // if routeEventId exists get info

                if (routeEventId) {
                    my.eventRef = base.syncState(`AdminEvents/${routeAdminName}/${routeEventId}`, {
                        context: my,
                        state: 'event',
                        then() {
                            my.setState({ synced: true })
                            if (my.state.event.EventName) {

                                // Event exists get event details
                                
                                my.setState({
                                    isDuplicate: true,
                                    eventName: this.state.event.EventName,
                                    eventStart: new Date(this.state.event.EventStart*1000),
                                    eventEnd: new Date(this.state.event.EventStop*1000),
                                    venueName: this.state.event.Address1,
                                    addressTwo: this.state.event.Address2,
                                    addressThree: this.state.event.Address3,
                                    link: this.state.event.EventLink,
                                    eventImage: this.state.event.EventImage,
                                    eventImageUrl: this.state.event.EventImage,
                                    cancelEvent: this.state.event.CancelEvent
                                })
                            } else {
                            my.setState({ redirect: true })
                            my.setState({ loading: false })
                            }
                        }
                        })
                }

                // get hostName and hostLogo and associatedCalendars

                if (my.state.adminType === 'Admin') {
                    const adminRef = firebaseApp.database().ref('/Businesses/' + my.state.adminName)
                    adminRef.once('value').then(function(adminSnapshot) {
                        my.setState({
                            hostName: adminSnapshot.val().Name,
                            hostLogo: adminSnapshot.val().Logo,
                            hostCalendarOne: adminSnapshot.val().Pass1,
                            hostCalendarTwo: adminSnapshot.val().Pass2,
                            hostCalendarThree: adminSnapshot.val().Pass3,
                            hostCalendarFour: adminSnapshot.val().Pass4,
                        })
                        my.setState({ loading: false })
                      })
                }
                if (my.state.adminType === 'CalendarAdmin') {
                    const adminRef = firebaseApp.database().ref('/CalendarAdmins/' + my.state.adminName)
                    adminRef.once('value').then(function(adminSnapshot) {
                        my.setState({
                            hostName: adminSnapshot.val().name,
                            hostLogo: adminSnapshot.val().thumbnail,
                            hostCalendarOne: adminSnapshot.val().city
                        })
                        my.setState({ loading: false })
                      })
                }
                if (my.state.adminType === 'CityAdmin') {
                    const adminRef = firebaseApp.database().ref('/Cities/' + my.state.adminName)
                    adminRef.once('value').then(function(adminSnapshot) {
                        my.setState({
                            hostName: adminSnapshot.val().name,
                            hostLogo: adminSnapshot.val().thumbnail,
                            hostCalendarOne: adminSnapshot.val().city
                        })
                        my.setState({ loading: false })
                      })
                }
                if (my.state.adminType === 'PassAdmin') {
                    const adminRef = firebaseApp.database().ref('/Passes/' + my.state.adminName)
                    adminRef.once('value').then(function(adminSnapshot) {
                        my.setState({
                            hostName: `What's Up ${adminSnapshot.val().name}?`,
                            hostLogo: adminSnapshot.val().ThumbnailImage,
                            hostCalendarOne: adminSnapshot.val().name
                        })
                        my.setState({ loading: false })
                      })
                }
            } else {
                my.setState({ redirect: true })
                my.setState({ loading: false })
            }
        }
  }

    componentWillUnmount() {
        this._isMounted = false
        if (this.state.synced) {
            base.removeBinding(this.eventRef)
            }
    }

    render() {

        const { eventStart } = this.state
        const { eventEnd } = this.state

        var eventParagraph = 'Event Added'
        var eventButtonText = 'Add Event'

        if (this.state.isEdit === true) {
            eventParagraph = 'Event Updated'
            eventButtonText = 'Update Event'
        }
        
    if (this.state.loading !== true) {

        // if adminNames do not match redirect to login

        if (this.state.authenticatedAdminMatch !== true) {
            return <Redirect to='/login'/>
        }

        if (this.state.redirect === true) {
            return <NotFound />
        }

        if (this.state.eventAdded === true) {
            return (
                <div className="AdminAddEventBackground">
                    <div className="AdminAddEventBannerBar">
                    </div>
                    <div className="AdminAddEventSpinnerContainer">
                        <div className="AdminEventAddedContainer">
                            <p>{eventParagraph}</p>
                            <Link to='/admin'>
                                <button className="btn grey darken-4">OK</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        }
        if (this.state.isDuplicate === true) {
            // if link None display blank field
            var link = this.state.link
            if (link === 'None' || link === 'none') {
                link = ''
            }
            return (
                <div className="AdminAddEventBackground">
                    <div className="AdminAddEventBannerBar">
                    </div>
                    <div className="AdminAddEventContainer">
                        <div className="AdminAddEvent">
                            <h1>{eventButtonText}</h1>
                            <form onSubmit={this.handleSubmit} className="AdminAddEventForm">
                                <div className="eventName">
                                    <TextInput placeholder="Event Name*" data-length={30} id="eventName" value={this.state.eventName} onChange={this.handleChange}/>
                                </div>
                                <div className="AdminAddEventPickerContainer">
                                    <div className="AdminAddEventStartPlaceholder">
                                        <p>Event Start*</p>
                                    </div>
                                    <Flatpickr data-enable-time value={eventStart} onChange={eventStart => { this.setState({eventStart}) }}
                                        options={{altInput: true, monthSelectorType: 'static', minuteIncrement: 1}}
                                    />
                                    <div className="AdminAddEventEndPlaceholder">
                                        <p>Event End*</p>
                                    </div>
                                    <Flatpickr data-enable-time value={eventEnd} onChange={eventEnd => { this.setState({eventEnd}) }}
                                        options={{altInput: true, monthSelectorType: 'static', minuteIncrement: 1}}
                                    />
                                </div>
                                <TextInput placeholder="Venue Name*" id="venueName" value={this.state.venueName} onChange={this.handleChange}/>
                                <TextInput placeholder="Address*" id="addressTwo" value={this.state.addressTwo} onChange={this.handleChange}/>
                                <TextInput placeholder="City, State Zip*" id="addressThree" value={this.state.addressThree} onChange={this.handleChange}/>
                                <TextInput placeholder="Link (optional)" id="link" value={link} onChange={this.handleChange}/>
                                <div className="AdminAddEventImageContainer">
                                    <input style={{display: 'none'}} type="file" onChange={this.fileSelectedHandler}
                                    ref={fileInput => this.fileInput = fileInput} />
                                    <img onClick={() => this.fileInput.click()} className="AdminAddEventImagePlaceholder" src={require('../../images/icon-image.svg')} alt="upload"/>
                                    <AdminAddEventImageView imageUrl={this.state.eventImageUrl}/>
                                </div>
                                <div className="input-field">
                                    <button className="btn grey darken-4">{eventButtonText}</button>
                                    <div className="red-text">
                                        {this.state.formError ? <p>{this.state.formError}</p> : null}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                )
        } else {
            return (
                <div className="AdminAddEventBackground">
                    <div className="AdminAddEventBannerBar">
                    </div>
                    <div className="AdminAddEventContainer">
                        <div className="AdminAddEvent">
                            <h1>{eventButtonText}</h1>
                            <form onSubmit={this.handleSubmit} className="AdminAddEventForm">
                                <div className="eventName">
                                    <TextInput placeholder="Event Name*" data-length={30} id="eventName" onChange={this.handleChange}/>
                                </div>
                                <div className="AdminAddEventPickerContainer">
                                    <div className="AdminAddEventStartPlaceholder">
                                        <p>Event Start*</p>
                                    </div>
                                    <Flatpickr data-enable-time value={eventStart} onChange={eventStart => { this.setState({eventStart}) }}
                                        options={{altInput: true, monthSelectorType: 'static', minuteIncrement: 1}}
                                    />
                                    <div className="AdminAddEventEndPlaceholder">
                                        <p>Event End*</p>
                                    </div>
                                    <Flatpickr data-enable-time value={eventEnd} onChange={eventEnd => { this.setState({eventEnd}) }}
                                        options={{altInput: true, monthSelectorType: 'static', minuteIncrement: 1}}
                                    />
                                </div>
                                <TextInput placeholder="Venue Name*" id="venueName" onChange={this.handleChange}/>
                                <TextInput placeholder="Address*" id="addressTwo" onChange={this.handleChange}/>
                                <TextInput placeholder="City, State Zip*" id="addressThree" onChange={this.handleChange}/>
                                <TextInput placeholder="Link (optional)" id="link" onChange={this.handleChange}/>
                                <div className="AdminAddEventImageContainer">
                                    <input style={{display: 'none'}} type="file" onChange={this.fileSelectedHandler}
                                    ref={fileInput => this.fileInput = fileInput} />
                                    <img onClick={() => this.fileInput.click()} className="AdminAddEventImagePlaceholder" src={require('../../images/icon-image.svg')} alt="upload"/>
                                    <AdminAddEventImageView imageUrl={this.state.eventImageUrl}/>
                                </div>
                                <div className="input-field">
                                    <button className="btn grey darken-4">{eventButtonText}</button>
                                    <div className="red-text">
                                        {this.state.formError ? <p>{this.state.formError}</p> : null}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                )
        }     
    } else {
        return (
            <div className="EventDetailsBackground">
                <div className="EventDetailsBannerBar">
                </div>
                <div className="EventDetailsSpinnerContainer">
                    <div className="preloader-wrapper active">
                        <div className="spinner-layer spinner-blue-only">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div><div className="gap-patch">
                            <div className="circle"></div>
                        </div><div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    }
}
  export default AdminAddEvent