import React from 'react'
import './admin.css'
import '../components/events/Events.css'
import AdminEventList from '../components/events/AdminEventList'
import {Redirect} from 'react-router-dom'
import { base, firebaseApp } from '../index'
import {Link} from 'react-router-dom'


class Admin extends React.Component {

    _isMounted = false

    constructor (props) {
        super(props)

        this.state = {
            businessAdmin: false,
            cityAdmin: false,
            passAdmin: false,
            calendarAdmin: false,
            events: {},
            loading: true,
            adminName: null,
            authenticated: false,
            logo: null
        }
    }

    componentDidMount(){

        this._isMounted = true
        const my = this

        firebaseApp.auth().onAuthStateChanged(function(user) {
            if (user) {
              // User is signed in
              if (my._isMounted) {
              my.setState({authenticated: true})
              fetchUserData(user)
              }
            } else {
              // User is signed out
              if (my._isMounted) {
              my.setState({ loading: false })
              }
            }
        })

        async function fetchUserData (user) {

            const userId = user.uid
            const snapshot = await firebaseApp.database().ref('/Users/' + userId).once('value')

            if (snapshot.val().Admin) {
                if (snapshot.val().Admin !== 'None') {
                    if (my._isMounted) {
                    my.setState({businessAdmin: true})
                    my.setState({adminName: snapshot.val().Admin})
                    my.eventRef = base.syncState(`AdminEvents/${snapshot.val().Admin}`, {
                        context: my,
                        state: 'events',
                        asArray: true,
                        queries: {
                        orderByChild: 'EventStart'
                        },
                        then() {
                        if (my._isMounted) {
                            my.setState({ loading: false })
                            return firebaseApp.database().ref('/Businesses/' + snapshot.val().Admin).once('value').then(function(logoSnapshot) {
                                my.setState({ logo: logoSnapshot.val().Logo })
                            })
                        }
                        }
                    })
                    }
                }
            }
            if (snapshot.val().CalendarAdmin) {
                if (snapshot.val().CalendarAdmin !== 'None') {
                    if (my._isMounted) {
                    my.setState({calendarAdmin: true})
                    my.setState({adminName: snapshot.val().CalendarAdmin})
                    my.eventRef = base.syncState(`AdminEvents/${snapshot.val().CalendarAdmin}`, {
                        context: my,
                        state: 'events',
                        asArray: true,
                        queries: {
                        orderByChild: 'EventStart'
                        },
                        then() {
                        if (my._isMounted) {
                            my.setState({ loading: false })
                            return firebaseApp.database().ref('/CalendarAdmins/' + snapshot.val().CalendarAdmin).once('value').then(function(logoSnapshot) {
                                my.setState({ logo: logoSnapshot.val().thumbnail })
                            })
                        }
                        }
                    })
                    }
                }
            }
            if (snapshot.val().CityAdmin) {
                if (snapshot.val().CityAdmin !== 'None') {
                    if (my._isMounted) {
                    my.setState({cityAdmin: true})
                    my.setState({adminName: snapshot.val().CityAdmin})
                    my.eventRef = base.syncState(`AdminEvents/${snapshot.val().CityAdmin}`, {
                        context: my,
                        state: 'events',
                        asArray: true,
                        queries: {
                        orderByChild: 'EventStart'
                        },
                        then() {
                        if (my._isMounted) {
                            my.setState({ loading: false })
                            return firebaseApp.database().ref('/Cities/' + snapshot.val().CityAdmin).once('value').then(function(logoSnapshot) {
                                my.setState({ logo: logoSnapshot.val().thumbnail })
                            })
                        }
                        }
                    })
                    }
                }
            }
            if (snapshot.val().PassAdmin) {
                if (snapshot.val().PassAdmin !== 'None') {
                    if (my._isMounted) {
                    my.setState({passAdmin: true})
                    my.setState({adminName: snapshot.val().PassAdmin})
                    my.eventRef = base.syncState(`AdminEvents/${snapshot.val().PassAdmin}`, {
                        context: my,
                        state: 'events',
                        asArray: true,
                        queries: {
                        orderByChild: 'EventStart'
                        },
                        then() {
                        if (my._isMounted) {
                            my.setState({ loading: false })
                            return firebaseApp.database().ref('/Passes/' + snapshot.val().PassAdmin).once('value').then(function(logoSnapshot) {
                                my.setState({ logo: logoSnapshot.val().ThumbnailImage })
                            })
                        }
                        }
                    })
                    }
                }
            }

            // Here is will reach this every time?

            // user NOT an admin
            my.setState({ loading: false })
        }
    }

    componentWillUnmount() {
        this._isMounted = false
        if (this.state.adminName !== null) {
            base.removeBinding(this.eventRef)
          }
      }

    
    render() {

        if (this.state.loading !== true) {

            // user must be signed in to access this route

            if (this.state.authenticated !== true) {
                return <Redirect to='/login'/>
            } else {
                if (this.state.businessAdmin === true || this.state.cityAdmin === true || 
                    this.state.passAdmin === true || this.state.calendarAdmin === true) {

                        // user HAS admin priveledges
        
                            const events = this.state.events
                        
                              return (
                                <div className="EventsContainer">
                                    <div className="EventsBannerBar">
                                    </div>
                                    <div className="EventsCityBanner">
                                        <h1>Admin</h1>
                                        <div className="EventsCityTextContainer">
                                            <div className="EventsCityText">
                                            <p>{this.state.adminName} Events</p>
                                            </div>
                                            <div className="EventsCityBannerImage">
                                            <img src={this.state.logo} alt="admin logo"/>
                                            </div>
                                        </div>
                                        <Link to={`/add-event/` + this.state.adminName}>
                                            <div className="input-field">
                                                <div className="waves-effect waves-light grey darken-4 btn-large">
                                                    <i className="material-icons left">add</i>Add Event
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="EventsDetail">
                                        <AdminEventList events={events} adminName={this.state.adminName} businessAdmin={this.state.businessAdmin} 
                                        cityAdmin={this.state.cityAdmin} passAdmin={this.state.passAdmin} calendarAdmin={this.state.calendarAdmin}/>
                                    </div>
                                </div>
                              )
                } else {
                    // user does NOT have admin priveledges
                    return (
                        <div className="AdminBackground">
                            <div className="AdminBannerBar">
                            </div>
                            <div className="AdminDetailContainer">
                                <div className="AdminDetail">
                                    <h1>Admin</h1>
                                    <p>
                                    It looks like you're not an admin.  If you're interested in being a 
                                    business sponsor or adding your events to a community calendar, please 
                                    email us at <a href="mailto:info@whatsuptexas.com">info@whatsuptexas.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        } else {
            return (
                <div className="EventsContainer">
                  <div className="EventsBannerBar">
                  </div>
                  <div className="EventsSpinnerContainer">
                    <div className="preloader-wrapper active">
                        <div className="spinner-layer spinner-blue-only">
                            <div className="circle-clipper left">
                            <div className="circle"></div>
                            </div><div className="gap-patch">
                            <div className="circle"></div>
                            </div><div className="circle-clipper right">
                            <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              )
        } 
    }
}

export default Admin




