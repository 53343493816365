import React from 'react'
import './EventDetails.css'
import {base, firebaseApp} from '../../index'
import NotFound from '../../pages/notFound'
import {Redirect} from 'react-router-dom'
import EventDetailsImageView from './EventDetailsImageView'
import EventDetailsLinkView from './EventDetailsLinkView'

class AdminEventDetails extends React.Component {

_isMounted = false
adminEventId = null

  constructor() {
      super()
      this.state = {
        event: {},
        loading: true,
        redirect: false,
        adminName: null,
        adminType: null,
        hostCalendarOne: null,
        hostCalendarTwo: null,
        hostCalendarThree: null,
        hostCalendarFour: null,
        authenticatedAdminMatch: false,
        synced: false,
        deleteEventPressed: false,
        formError: null
      }
  }

    cancelButtonClicked = () => {
        this.setState({
            deleteEventPressed: false
        })
    }

    deleteButtonClicked = () => {
        if (this.state.deleteEventPressed === true) {

            // delete event and redirect to admin

            this.setState({
                loading: true
            })
            this.deleteEvent()

        } else {
            this.setState({
                deleteEventPressed: true
            })
        }
    }

    deleteEvent() {

        const myThis = this

        var postData = {
            BusinessName: null,
            UserId: null,
            Logo: null,
            EventName: null,
            EventStart: null,
            EventStop: null,
            EventLink: null,
            EventImage: null,
            Address1: null,
            Address2: null,
            Address3: null,
            CancelEvent: null
        }

        var updates = {}
        updates[`/AdminEvents/${this.state.adminName}/` + this.adminEventId] = postData

        if (this.state.hostCalendarFour && this.state.hostCalendarFour !== 'None' && this.state.hostCalendarFour !== 'none') {
            updates[`/Events/${this.state.hostCalendarFour}/` + this.adminEventId] = postData
            updates[`/Events/${this.state.hostCalendarThree}/` + this.adminEventId] = postData
            updates[`/Events/${this.state.hostCalendarTwo}/` + this.adminEventId] = postData
            updates[`/Events/${this.state.hostCalendarOne}/` + this.adminEventId] = postData
            return firebaseApp.database().ref().update(updates,
                function(error) {
                if (error) {
                    myThis.setState({
                        formError: 'Unable to delete event, please try again later...',
                        loading: false
                    })
                } else {
                    // event successfully deleted
                    myThis.setState({
                        redirect: true,
                        loading: false
                    })
                }
              })
        } else {
            if (this.state.hostCalendarThree && this.state.hostCalendarThree !== 'None' && this.state.hostCalendarThree !== 'none') {
                updates[`/Events/${this.state.hostCalendarThree}/` + this.adminEventId] = postData
                updates[`/Events/${this.state.hostCalendarTwo}/` + this.adminEventId] = postData
                updates[`/Events/${this.state.hostCalendarOne}/` + this.adminEventId] = postData
                return firebaseApp.database().ref().update(updates,
                    function(error) {
                    if (error) {
                        myThis.setState({
                            formError: 'Unable to delete event, please try again later...',
                            loading: false
                        })
                    } else {
                        // event successfully deleted
                        myThis.setState({
                            redirect: true,
                            loading: false
                        })
                    }
                  })
            } else {
                if (this.state.hostCalendarTwo && this.state.hostCalendarTwo !== 'None' && this.state.hostCalendarTwo !== 'none') {
                    updates[`/Events/${this.state.hostCalendarTwo}/` + this.adminEventId] = postData
                    updates[`/Events/${this.state.hostCalendarOne}/` + this.adminEventId] = postData
                    return firebaseApp.database().ref().update(updates,
                        function(error) {
                        if (error) {
                            myThis.setState({
                                formError: 'Unable to delete event, please try again later...',
                                loading: false
                            })
                        } else {
                            // event successfully deleted
                        myThis.setState({
                            redirect: true,
                            loading: false
                        })
                        }
                      })
                } else {
                    updates[`/Events/${this.state.hostCalendarOne}/` + this.adminEventId] = postData
                    return firebaseApp.database().ref().update(updates,
                        function(error) {
                        if (error) {
                            myThis.setState({
                                formError: 'Unable to delete event, please try again later...',
                                loading: false
                            })
                        } else {
                            // event successfully deleted
                        myThis.setState({
                            redirect: true,
                            loading: false
                        })
                        }
                      })
                }
            }
        }
    }

    componentDidMount(){
        const eventId = this.props.match.params.id
        const routeAdminName = this.props.match.params.adminName

        this.adminEventId = eventId

        this._isMounted = true
        const my = this

        firebaseApp.auth().onAuthStateChanged(function(user) {
            if (user) {
              // User is signed in
              if (my._isMounted) {
                fetchUserData(user)
              }
            } else {
              // User is signed out
              if (my._isMounted) {
                my.setState({ redirect: true })
                my.setState({ loading: false })
              }
            }
        })

        async function fetchUserData (user) {

            const userId = user.uid
            const snapshot = await firebaseApp.database().ref('/Users/' + userId).once('value')

            if (snapshot.val().Admin) {
                if (snapshot.val().Admin !== 'None') {
                    if (my._isMounted) {
                    my.setState({adminName: snapshot.val().Admin})
                    my.setState({adminType: 'Admin'})
                    }
                }
            }
            if (snapshot.val().CalendarAdmin) {
                if (snapshot.val().CalendarAdmin !== 'None') {
                    if (my._isMounted) {
                    my.setState({adminName: snapshot.val().CalendarAdmin})
                    my.setState({adminType: 'CalendarAdmin'})
                    }
                }
            }
            if (snapshot.val().CityAdmin) {
                if (snapshot.val().CityAdmin !== 'None') {
                    if (my._isMounted) {
                    my.setState({adminName: snapshot.val().CityAdmin})
                    my.setState({adminType: 'CityAdmin'})
                    }
                }
            }
            if (snapshot.val().PassAdmin) {
                if (snapshot.val().PassAdmin !== 'None') {
                    if (my._isMounted) {
                    my.setState({adminName: snapshot.val().PassAdmin})
                    my.setState({adminType: 'PassAdmin'})
                    }
                }
            }

            // verify adminNames match

            if (my.state.adminName === routeAdminName) {
                my.setState({ authenticatedAdminMatch: true })
                my.eventRef = base.syncState(`AdminEvents/${routeAdminName}/${eventId}`, {
                    context: my,
                    state: 'event',
                    then() {
                        my.setState({ synced: true })
                        if (my.state.event.EventName) {

                            // get associatedCalendars

                            if (my.state.adminType === 'Admin') {
                                const adminRef = firebaseApp.database().ref('/Businesses/' + my.state.adminName)
                                adminRef.once('value').then(function(adminSnapshot) {
                                    my.setState({
                                        hostCalendarOne: adminSnapshot.val().Pass1,
                                        hostCalendarTwo: adminSnapshot.val().Pass2,
                                        hostCalendarThree: adminSnapshot.val().Pass3,
                                        hostCalendarFour: adminSnapshot.val().Pass4,
                                    })
                                    my.setState({ loading: false })
                                })
                            }
                            if (my.state.adminType === 'CalendarAdmin') {
                                const adminRef = firebaseApp.database().ref('/CalendarAdmins/' + my.state.adminName)
                                adminRef.once('value').then(function(adminSnapshot) {
                                    my.setState({
                                        hostCalendarOne: adminSnapshot.val().city
                                    })
                                    my.setState({ loading: false })
                                })
                            }
                            if (my.state.adminType === 'CityAdmin') {
                                const adminRef = firebaseApp.database().ref('/Cities/' + my.state.adminName)
                                adminRef.once('value').then(function(adminSnapshot) {
                                    my.setState({
                                        hostCalendarOne: adminSnapshot.val().city
                                    })
                                    my.setState({ loading: false })
                                })
                            }
                            if (my.state.adminType === 'PassAdmin') {
                                const adminRef = firebaseApp.database().ref('/Passes/' + my.state.adminName)
                                adminRef.once('value').then(function(adminSnapshot) {
                                    my.setState({
                                        hostCalendarOne: adminSnapshot.val().name
                                    })
                                    my.setState({ loading: false })
                                })
                            }
                        } else {
                        my.setState({ redirect: true })
                        my.setState({ loading: false })
                        }
                    }
                    })
            } else {
                my.setState({ redirect: true })
                my.setState({ loading: false })
            }
        }
  }

    componentWillUnmount() {
        this._isMounted = false
        if (this.state.synced) {
        base.removeBinding(this.eventRef)
        }
    }

    render() {

    if (this.state.loading !== true) {

        // if adminNames do not match redirect to login

        if (this.state.authenticatedAdminMatch !== true) {
            return <Redirect to='/login'/>
        }

        if (this.state.redirect === true && this.state.deleteEventPressed === false) {
            return <NotFound />
        }

        if (this.state.redirect === true && this.state.deleteEventPressed === true) {
            return <Redirect to='/admin'/>
        }

        if (this.state.deleteEventPressed === true) {
            return (
                <div className="EventDetailsBackground">
                    <div className="EventDetailsBannerBar">
                    </div>
                    <div className="EventDetailsSpinnerContainer">
                        <div className="EventDetailsDeleteContainer">
                            <p>Are you sure you want to delete {this.state.event.EventName}?</p>
                            <div className="EventDetailsDeleteButtonsContainer">
                                <div onClick={this.cancelButtonClicked} className="EventDetailsCancelButton">
                                    <button className="btn grey darken-3">cancel</button>
                                </div>
                                <div onClick={this.deleteButtonClicked} className="EventDetailsDeleteButton">
                                    <button className="btn red">delete</button>
                                </div>
                            </div>
                            <div className="red-text">
                                {this.state.formError ? <h5>{this.state.formError}</h5> : null}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            const event = this.state.event
            const moment = require('moment')
            const eventName = event.EventName
            const eventStart = moment.unix(event.EventStart).format('dddd, MMMM Do, YYYY')
            const eventStartHour = moment.unix(event.EventStart).format('h:mm A')
            const eventEndHour = moment.unix(event.EventStop).format('h:mm A')
            const mapPreFix = 'https://www.google.com/maps/search/?api=1&query='

        return (
        <div className="EventDetailsBackground">
            <div className="EventDetailsBannerBar">
            </div>
            <div className="EventDetailsButtonBar">
                <a href={`/add-event/${this.state.adminName}/` + this.adminEventId} className="waves-effect waves-light btn blue"><i className="material-icons left">add</i>duplicate</a>
                <a href={`/edit-event/${this.state.adminName}/` + this.adminEventId} className="waves-effect waves-light btn grey darken-3"><i className="material-icons left">edit</i>edit</a>
                <button onClick={this.deleteButtonClicked} className="waves-effect waves-light btn red"><i className="material-icons left">delete</i>delete</button>
            </div>
            <div className="EventDetailsGroupContainer">
            <div className="EventTitleDateContainer">
                <h3>{eventName}</h3>
                <p>{eventStart}</p>
                <h1>{eventStartHour}-{eventEndHour}</h1>
            </div>
            <a href={`${mapPreFix}+${this.state.event.Address1}+&query=+${this.state.event.Address2}+,+${this.state.event.Address3}`} target="_blank" rel="noopener noreferrer">
                <div className="EventDetailsAddressIconContainer">
                <div className="EventDetailsAddressContainer">
                    <div className="EventDetailsAddressOneContainer">
                    <p>{this.state.event.Address1}</p>
                    </div>
                    <div className="EventDetailsAddressTwoContainer">
                    <p>{this.state.event.Address2}</p>
                    </div>
                    <div className="EventDetailsAddressThreeContainer">
                    <p>{this.state.event.Address3}</p>
                    </div>
                </div>
                <img src={require('../../images/icon-map-marker-blue.svg')} alt="location icon"/>
                </div>
            </a>
            <div className="EventDetailsPresentedLearnContainer">
                <div className="EventDetailsPresentedContainer">
                    <div className="PresentedBy">
                        <h3>Presented By</h3>
                        <p>{this.state.event.BusinessName}</p>
                    </div>
                    <img src={this.state.event.Logo} alt="event host logo"/>
                </div>
                <EventDetailsLinkView linkUrl={this.state.event.EventLink}/>
            </div>
            <div className="EventDetailsImageContainer">
                <EventDetailsImageView imageUrl={this.state.event.EventImage}/>
            </div>
            </div>
        </div>
        )
        } 
    } else {
        return (
        <div className="EventDetailsBackground">
            <div className="EventDetailsBannerBar">
            </div>
            <div className="EventDetailsSpinnerContainer">
                <div className="preloader-wrapper active">
                    <div className="spinner-layer spinner-blue-only">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div><div className="gap-patch">
                        <div className="circle"></div>
                    </div><div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    }
}
  export default AdminEventDetails