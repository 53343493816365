import React from 'react'
import './about.css'
import {Helmet} from 'react-helmet'

function About() {
    return (
        <div className="AboutBackground">
            <Helmet>
                <meta name="apple-itunes-app" content="app-id=1346208825"/>
                <title>About | What's Up Texas?</title>
                <meta name="description" content="Be Loyal.  Save Moolah.  Be a part of the community." />
                <meta property="og:title" content="About | What's Up Texas?" />
                <meta property="og:description" content="Be Loyal.  Save Moolah.  Be a part of the community." />
                <meta property="og:image" content={require(`../images/Whats_Up_Texas_with_Pat_McGrail.jpeg`)} />
                <meta property="twitter:title" content="About | What's Up Texas?" />
                <meta property="twitter:description" content="Be Loyal.  Save Moolah.  Be a part of the community." />
                <meta property="twitter:image" content={require(`../images/Whats_Up_Texas_with_Pat_McGrail.jpeg`)} />
            </Helmet>
            <div className="AboutBannerBar">
            </div>
            <div className="AboutDetailContainer">
                <div className="AboutDetail">
                    <h1>ABOUT US</h1>
                    <h3>BE LOYAL.  SAVE MOOLAH.  BE A PART OF THE COMMUNITY.</h3>
                    <p>
                    We’re a family of four from Keller, TX.  We started What’s Up Texas? in March of 2013 
                    when we created our first What’s Up? loyalty pass and named it after our hometown.  
                    Our goal was to save money around town without having to keep track of multiple coupons 
                    and loyalty cards.  In 2019, we added a community calendar so that we could stay in 
                    the loop on local events.  We hope you enjoy using the app, saving some moolah and 
                    connecting with your community.
                    </p>
                    <div className="AboutImageContainer">
                        <img src={require('../images/Whats_Up_Texas_with_Pat_McGrail.jpeg')} alt="About Whats Up Texas"/>
                        <div className="AboutImageFooter">
                            <h4>What's Up Texas?</h4>
                            <div className="AboutImageFooterText">
                                with Mayor of Keller, TX, Mr. Pat McGrail - November 2013
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About