import React from 'react'
import './EventSummary.css'
import {Link} from 'react-router-dom'

var previousMonthYear = 'None'
var monthYearSame = false
var eventMonth = 'None'

const AdminEventSummary = (props) => {

    const event = props.value
    const adminName = props.adminName

    const moment = require('moment')
    const eventDayOfMonth = moment.unix(event.EventStart).format('D')
    const eventDayOfWeek = moment.unix(event.EventStart).format('ddd')
    const eventStartHour = moment.unix(event.EventStart).format('h:mm A')
    const eventEndHour = moment.unix(event.EventStop).format('h:mm A')
    const eventMonthYear = moment.unix(event.EventStop).format('M YYYY')
    eventMonth = moment.unix(event.EventStop).format('MMMM')

    if (eventMonthYear === previousMonthYear) {
        monthYearSame = true
        previousMonthYear = eventMonthYear
    } else {
        monthYearSame = false
        previousMonthYear = eventMonthYear
    }
    
    return (
        <div>
            <div>
                { monthYearSame ? null : <Section />}
            </div>
            <Link to={`/admin/${adminName}/` + event.key} key={event.key} >
                <div className="EventRow">
                    <div className="EventSquare">
                        <h3>{eventDayOfMonth}</h3>
                        <h5>{eventDayOfWeek}</h5>
                    </div>
                    <div className="EventDetailsContainer">
                        <div className="EventDetails">
                            <div className="EventTime">
                                <h3>{eventStartHour}</h3>
                                <h1>-</h1>
                                <h3>{eventEndHour}</h3>
                            </div>
                            <p>{event.EventName}</p>
                            <h5>{event.Address1}</h5>
                        </div>
                    </div>
                    <div className="EventHostLogoContainer">
                        <img src={event.Logo} alt="event host logo"/>
                    </div>
                </div>
            </Link>
        </div> 
    )
}

const Section = () => {
    return (
        <div className="Section">
            <p>{eventMonth}</p>
        </div>
    )
}

export default AdminEventSummary