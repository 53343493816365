import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import { firebaseConnect } from "react-redux-firebase"
import BusinessList from '../businesses/BusinessList'

class Businesses extends Component {

    render() {
      const {businesses} = this.props

      return (
        <div className="BusinessCardContainerOne">
          <div className="BusinessCardContainerTwo">
            <div className="BusinessCardContainerThree">
              <div className="BusinessCardContainerFour">
                  <BusinessList businesses={businesses}/>
              </div>
            </div>
          </div>
        </div> 
      )
    }
  }

  const mapStateToProps = (state) => {

    return {
        businesses: state.firebase.ordered.Businesses
    }
  }

  export default compose(
    connect(mapStateToProps),

    firebaseConnect([
      {
        path: 'Businesses'
      }
    ])
  )(Businesses)