import React, {Component} from 'react'
import EventList from '../events/EventList'
import './Events.css'
import {base} from '../../index'
import NotFound from '../../pages/notFound'
import {Helmet} from 'react-helmet'
import { firebaseAnalytics } from '../../index'

class Events extends Component {

  constructor() {
      super()
      this.state = {
          events: {},
          loading: true,
          redirect: false,
          calendarName: 'City'
        }
  }

    componentDidMount(){

      const city = this.props.match.params.city
      const cityLowerCase = city.toLowerCase()

      const todayDate = new Date()
      const moment = require('moment')
      const epoch = moment(todayDate).unix()

      const analytics = firebaseAnalytics
      analytics.logEvent(`${city}_calendar_page_viewed`)

      analytics.logEvent('web_page_viewed', {
        web_page_title: `${city}_calendar`
      })

      if (cityLowerCase === 'keller' || cityLowerCase === 'alliance') {

        if (cityLowerCase === 'keller') {
          this.eventRef = base.syncState('Events/Keller', {
            context: this,
            state: 'events',
            asArray: true,
            queries: {
              orderByChild: 'EventStart',
              startAt: epoch
            },
            then() {
              this.setState({ calendarName: 'Keller' })
              this.setState({ loading: false })
            }
          })
        } else {
          this.eventRef = base.syncState('Events/Alliance', {
            context: this,
            state: 'events',
            asArray: true,
            queries: {
              orderByChild: 'EventStart',
              startAt: epoch
            },
            then() {
              this.setState({ calendarName: 'Alliance' })
              this.setState({ loading: false })
            }
          })
        }
      } else {
        this.setState({
          redirect: true
        })
      }
  }
    
  componentWillUnmount() {
    if (this.state.calendarName !== 'City') {
      base.removeBinding(this.eventRef)
    }
  }

  render() {

    if (this.state.redirect === true) {
      return <NotFound />
    }

    if (this.state.loading !== true) {

    const events = this.state.events
    const descriptionContent = `Community Calendar for ${this.state.calendarName}, TX - Keep up with what's happening around town.`
    const metaTitle = `${this.state.calendarName}, TX | What's Up Texas?`

      return (
        <div className="EventsContainer">
          <Helmet>
            <meta name="apple-itunes-app" content="app-id=1346208825"/>
            <title>{this.state.calendarName}, TX | What's Up Texas?</title>
            <meta name="description" content={descriptionContent} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={descriptionContent} />
            <meta property="og:image" content={require(`../../images/${this.state.calendarName}_Calendar.jpg`)} />
            <meta property="twitter:title" content={metaTitle} />
            <meta property="twitter:description" content={descriptionContent} />
            <meta property="twitter:image" content={require(`../../images/${this.state.calendarName}_Calendar.jpg`)} />
          </Helmet>
          <div className="EventsBannerBar">
          </div>
          <div className="EventsCityBanner">
              <h1>Community Calendar</h1>
              <div className="EventsCityTextContainer">
                  <div className="EventsCityText">
                  <p>{this.state.calendarName}, TX</p>
                  </div>
                  <div className="EventsCityBannerImage">
                  <img src={require(`../../images/${this.state.calendarName}_Calendar.jpg`)} alt="city calendar"/>
                  </div>
              </div>
          </div>
          <div className="EventsDetail">
              <EventList events={events} city={this.state.calendarName}/>
          </div>
        </div>
      )
    } else {
      return (
        <div className="EventsContainer">
          <div className="EventsBannerBar">
          </div>
          <div className="EventsSpinnerContainer">
            <div className="preloader-wrapper active">
                <div className="spinner-layer spinner-blue-only">
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div><div className="gap-patch">
                    <div className="circle"></div>
                </div><div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
                </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default Events