import React from 'react'
import FooterLinks from './FooterLinks'
import './Footer.css'

const Footer = () => {
    const todayDate = new Date()
    const moment = require('moment')
    const currentYear = moment(todayDate).format('YYYY')

        return (
            <div className="FooterContainer">
                <div className="FooterGroup">
                    <div className="FooterLinksGroup">
                        <FooterLinks />
                    </div> 
                </div>
                <div className="FooterInfo">
                    <p>
                    Copyright {currentYear} What's Up Keller? LLC.  All Rights Reserved.  
                    What's Up Texas? is a service mark of What's Up Keller? LLC.  
                    Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. 
                    App Store is a service mark of Apple Inc., registered in the U.S. and other countries.  
                    Google Play and the Google Play logo are trademarks of Google LLC.
                    </p>
                </div>
            </div>
        )
    }

export default Footer