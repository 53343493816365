import React, {Component} from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Navbar from './components/layout/Navbar'
import SignIn from './components/auth/SignIn'
import home from './pages/home'
import BusinessDetails from './components/businesses/BusinessDetails'
import Events from './components/events/Events'
import EventDetails from './components/events/EventDetails'
import Footer from './components/layout/Footer'
import ScrollToTop from './components/layout/ScrollToTop'
import terms from './pages/terms'
import sponsorTerms from './pages/sponsorTerms'
import exclusive from './pages/exclusive'
import about from './pages/about'
import notFound from './pages/notFound'
import admin from './pages/admin'
import { firebaseApp } from './index'
import AdminEventDetails from './components/events/AdminEventDetails'
import AdminAddEvent from './components/events/AdminAddEvent'
import forgotPassword from './pages/forgotPassword'
import './loading.css'

class App extends Component {

  constructor() {
    super()
    
    this.state = {
      authenticated: false,
      loading: true
    }
  }

  componentDidMount() {
    this.removeAuthListener = firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authenticated: true,
          loading: false
        })
      } else {
        this.setState({
          authenticated: false,
          loading: false
        })
      }
    })
  }

  componentWillUnmount() {
    this.removeAuthListener()
  }

  render() {

    if (this.state.loading === true) {
      return (
        <div className="LoadingContainer">
          <h3>Loading</h3>
        </div>
      )
    }

    return (
      <Router>
        <ScrollToTop />
        <div className="App">
          <Navbar authenticated={this.state.authenticated} />
          <Switch>
            <Route exact path='/' component={home} />
            <Route path='/businesses/:id' component={BusinessDetails} />
            <Route path='/events/:city/:id' component={EventDetails} />
            <Route path='/events/:city' component={Events} />
            <Route path='/login' component={SignIn} />
            <Route path='/forgot-password' component={forgotPassword} />
            <Route path='/pass-holder-terms' component={terms} />
            <Route path='/sponsor-terms-of-service' component={sponsorTerms} />
            <Route path='/be-exclusive' component={exclusive} />
            <Route path='/about' component={about} />
            <Route exact path='/admin' component={admin}/>
            <Route path='/admin/:adminName/:id' component={AdminEventDetails} />
            <Route path='/edit-event/:adminName/:id' component={AdminAddEvent} />
            <Route path='/add-event/:adminName/:id' component={AdminAddEvent} />
            <Route path='/add-event/:adminName' component={AdminAddEvent} />
            <Route path='*' component={notFound}/>
          </Switch>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App
