import React from 'react'
import './EventDetails.css'
import {base} from '../../index'
import NotFound from '../../pages/notFound'
import EventDetailsImageView from './EventDetailsImageView'
import EventDetailsLinkView from './EventDetailsLinkView'
import {Helmet} from 'react-helmet'
import { firebaseAnalytics } from '../../index'

class EventDetails extends React.Component {

  constructor() {
      super()
      this.state = {
        event: {},
        loading: true,
        redirect: false,
        calendarName: 'City'
      }
  }

    componentDidMount(){
    const eventId = this.props.match.params.id
    const city = this.props.match.params.city
    const cityLowerCase = city.toLowerCase()

    const analytics = firebaseAnalytics

    if (cityLowerCase === 'keller' || cityLowerCase === 'alliance') {

    if (cityLowerCase === 'keller') {
      this.eventRef = base.syncState(`Events/Keller/${eventId}`, {
        context: this,
        state: 'event',
        then() {
          this.setState({ calendarName: 'Keller' })
          if (this.state.event.EventName) {
            analytics.logEvent(`${city}_${this.state.event.EventName}_page_viewed`)

            analytics.logEvent('web_page_viewed', {
              web_page_title: `${this.state.event.EventName}`,
              web_page_title_event_city: `${city}`,
              web_page_title_event_host: `${this.state.event.BusinessName}`
            })



            this.setState({ loading: false })
          } else {
            this.setState({
              redirect: true
            })
          }
        }
      })
    } else {
      this.eventRef = base.syncState(`Events/Alliance/${eventId}`, {
        context: this,
        state: 'event',
        then() {
          this.setState({ calendarName: 'Alliance' })
          if (this.state.event.EventName) {
            analytics.logEvent(`${city}_${this.state.event.EventName}_page_viewed`)

            analytics.logEvent('web_page_viewed', {
              web_page_title: `${this.state.event.EventName}`,
              web_page_title_event_city: `${city}`,
              web_page_title_event_host: `${this.state.event.BusinessName}`
            })



            this.setState({ loading: false })
          } else {
            this.setState({
              redirect: true
            })
          }
        }
      })
    }

  } else {
    this.setState({
      redirect: true
    })
  } 
  }

  componentWillUnmount() {
    if (this.state.calendarName !== 'City') {
      base.removeBinding(this.eventRef)
    }
  }

render() {

  if (this.state.redirect === true) {
    return <NotFound />
  }

  if (this.state.loading !== true) {

    const event = this.state.event

    const moment = require('moment')
    const eventName = event.EventName
    const eventStart = moment.unix(event.EventStart).format('dddd, MMMM Do, YYYY')
    const eventStartHour = moment.unix(event.EventStart).format('h:mm A')
    const eventEndHour = moment.unix(event.EventStop).format('h:mm A')
    const mapPreFix = 'https://www.google.com/maps/search/?api=1&query='

    const descriptionContent = `Community Event hosted by ${this.state.event.BusinessName}`
    const metaTitle = `${eventName} | What's Up Texas?`

    return (
      <div className="EventDetailsBackground">
        <Helmet>
          <meta name="apple-itunes-app" content="app-id=1346208825"/>
          <title>{eventName} | What's Up Texas?</title>
          <meta name="description" content={descriptionContent} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={descriptionContent} />
          <meta property="og:image" content={this.state.event.Logo} />
          <meta property="twitter:title" content={metaTitle} />
          <meta property="twitter:description" content={descriptionContent} />
          <meta property="twitter:image" content={this.state.event.Logo} />
        </Helmet>
        <div className="EventDetailsBannerBar">
        </div>
        <div className="EventDetailsGroupContainer">
          <div className="EventTitleDateContainer">
            <h3>{eventName}</h3>
            <p>{eventStart}</p>
            <h1>{eventStartHour}-{eventEndHour}</h1>
          </div>
          <a href={`${mapPreFix}+${this.state.event.Address1}+&query=+${this.state.event.Address2}+,+${this.state.event.Address3}`} target="_blank" rel="noopener noreferrer">
            <div className="EventDetailsAddressIconContainer">
              <div className="EventDetailsAddressContainer">
                <div className="EventDetailsAddressOneContainer">
                  <p>{this.state.event.Address1}</p>
                </div>
                <div className="EventDetailsAddressTwoContainer">
                  <p>{this.state.event.Address2}</p>
                </div>
                <div className="EventDetailsAddressThreeContainer">
                  <p>{this.state.event.Address3}</p>
                </div>
              </div>
              <img src={require('../../images/icon-map-marker-blue.svg')} alt="location icon"/>
            </div>
          </a>
          <div className="EventDetailsPresentedLearnContainer">
            <div className="EventDetailsPresentedContainer">
                <div className="PresentedBy">
                    <h3>Presented By</h3>
                    <p>{this.state.event.BusinessName}</p>
                </div>
                <img src={this.state.event.Logo} alt="event host logo"/>
            </div>
            <EventDetailsLinkView linkUrl={this.state.event.EventLink}/>
          </div>
          <div className="EventDetailsImageContainer">
            <EventDetailsImageView imageUrl={this.state.event.EventImage}/>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="EventDetailsBackground">
        <div className="EventDetailsBannerBar">
        </div>
        <div className="EventDetailsSpinnerContainer">
          <div className="preloader-wrapper active">
              <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                  <div className="circle"></div>
              </div><div className="gap-patch">
                  <div className="circle"></div>
              </div><div className="circle-clipper right">
                  <div className="circle"></div>
              </div>
              </div>
          </div>
        </div>
      </div>
    )
    }
  }
}
  export default EventDetails
