import React from 'react'
import './BusinessDetail.css'
import './BusinessCard.css'
import {base} from '../../index'
import NotFound from '../../pages/notFound'
import {Helmet} from 'react-helmet'
import { firebaseAnalytics } from '../../index'

class BusinessDetails extends React.Component {

    constructor() {
        super()
        this.state = {
          business: {},
          loading: true,
          redirect: false
        }
    }

    componentDidMount(){
        const businessId = this.props.match.params.id

        const analytics = firebaseAnalytics

        this.businessRef = base.syncState(`Businesses/${businessId}`, {
            context: this,
            state: 'business',
            then() {
              if (this.state.business.Name) {
                analytics.logEvent(`${this.state.business.Name}_page_viewed`)

                analytics.logEvent('web_page_viewed', {
                    web_page_title: `${this.state.business.Name}`
                  })



                this.setState({ loading: false })
              } else {
                this.setState({
                  redirect: true
                })
              }
            }
        })
    }
    
    componentWillUnmount() {
        base.removeBinding(this.businessRef)
    }

    render() {
        if (this.state.redirect === true) {
            return <NotFound />
        }
    
        if (this.state.loading !== true) {
    
        const business = this.state.business

        const mapPreFix = 'https://www.google.com/maps/search/?api=1&query='
        const webPreFix = 'http://'
        const phonePreFix = 'tel:'
        const descriptionContent = business.Offer
        const metaTitle = `${business.Name} | What's Up Texas?`

        return (
        <div className="Container">
            <Helmet>
                <meta name="apple-itunes-app" content="app-id=1346208825"/>
                <title>{business.Name} | What's Up Texas?</title>
                <meta name="description" content={descriptionContent} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={descriptionContent} />
                <meta property="og:image" content={business.Logo} />
                <meta property="twitter:title" content={metaTitle} />
                <meta property="twitter:description" content={descriptionContent} />
                <meta property="twitter:image" content={business.Logo} />
            </Helmet>
            <div className="BannerBar">
            </div>
            <div className="BusinessDetail">
                <div className="BusinessBanner">
                    <div className="BusinessCardHolder">
                        <a href={webPreFix+business.Web} target="_blank" rel="noopener noreferrer">
                            <div className="BusinessCardLogo">
                                <img src={business.Logo} alt="business logo"/>
                            </div>
                        </a>  
                    </div>
                    <div className="BusinessName">
                        <h1>{business.Name}</h1>
                        <h3>{business.Subcategory}</h3>
                    </div>
                </div>
                <a className="BusinessImageLink" href={webPreFix+business.Web} target="_blank" rel="noopener noreferrer">
                    <div className="BusinessImageBanner">
                        <div className="BusinessImageLeft">
                            <img src={business.Image1} alt="business image1"/>
                        </div>
                        <div className="BusinessImageRight">
                            <img src={business.Image2} alt="business image2"/>
                        </div>
                    </div>
                </a>
                <div className="BusinessInfo">
                    <div className="BusinessOfferContainer">
                        <div className="BusinessOffer">
                            <h3>Current Offer</h3>
                            <p>{business.Offer}</p>
                        </div>
                        <img src={require('../../images/Business_Offer_Image.svg')} alt="savings icon"/>
                    </div>
                    <div className="BusinessPhoneContainer">
                        <div className="BusinessPhone">
                            <h3>Phone</h3>
                            <a href={phonePreFix+business.Phone}>
                                <p>{business.Phone}</p>
                            </a>
                        </div>
                        <img src={require('../../images/icon-phone.svg')} alt="phone icon"/>
                    </div>
                    <div className="BusinessWebContainer">
                        <div className="BusinessWeb">
                            <h3>Website</h3>
                            <a href={webPreFix+business.Web} target="_blank" rel="noopener noreferrer">
                                <p>{business.Web}</p>
                            </a>
                        </div>
                        <img src={require('../../images/icon-web.svg')} alt="web icon"/>
                    </div>
                        <div className="BusinessAddressContainer">
                            <div className="BusinessAddress">
                                <h3>Address</h3>
                                <a href={`${mapPreFix}+${business.Name}+&query=+${business.Address1}+,+${business.Address2}`} target="_blank" rel="noopener noreferrer">
                                    <p>{business.Address1}</p>
                                    <p>{business.Address2}</p>
                                </a>
                            </div>
                            <img src={require('../../images/icon-map-marker.svg')} alt="location icon"/>
                        </div>
                </div>
            </div>
        </div>
        )
        } else {
        return (
        <div className="Container">
            <div className="BannerBar">
            </div>
            <div className="BusinessDetailsSpinnerContainer">
                <div className="preloader-wrapper active">
                    <div className="spinner-layer spinner-blue-only">
                        <div className="circle-clipper left">
                        <div className="circle"></div>
                        </div><div className="gap-patch">
                        <div className="circle"></div>
                        </div><div className="circle-clipper right">
                        <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
        }
    }
}

export default BusinessDetails