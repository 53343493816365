import React from 'react'
import './sponsorTerms.css'
import { NavLink } from 'react-router-dom'

function SponsorTerms() {
    return (
        <div className="SponsorTermsBackground">
            <div className="SponsorTermsBannerBar">
            </div>
            <div className="SponsorTermsDetailContainer">
                <div className="SponsorTermsDetail">
                    <h1>Sponsor Terms Of Service</h1>
                    <p>Updated 2/27/2018</p>
                    <h5>Authorization</h5>
                    <p>
                    You authorize What's Up Keller? LLC to use the business name and logo 
                    that you provide to What's Up Keller? LLC on the What's Up Texas? website, 
                    What's Up Texas? mobile app, What's Up? loyalty pass(es), newsletter(s), 
                    social media and marketing materials.
                    </p>
                    <h5>Refund Policy</h5>
                    <p>
                    You understand that all monthly sponsorship dues payments are non-refundable.
                    </p>
                    <h5>Auto-Pay Authorization</h5>
                    <p>
                    You understand that What's Up Texas? business sponsors are required to pay 
                    invoices using auto-pay (a free service provided by What's Up Keller? LLC.)  
                    All payments are due on the 1st of each month for that month's sponsorship dues.  
                    Outstanding balances that are more than fifteen (15) days past due will result in 
                    account cancellation and removal from the What's Up Texas? website, mobile app and 
                    loyalty pass(es).  You authorize What's Up Keller? LLC to charge the credit or debit 
                    card that is on file in the What's Up Keller? LLC Client Portal for payment of your 
                    monthly sponsorship dues.
                    </p>
                    <h5>Exclusivity</h5>
                    <p>
                    Each business sponsor is placed in an exclusive subcategory that is defined by 
                    What's Up Keller? LLC in order to mitigate the occurrence of business sponsors providing 
                    similar products or services in a hometown.  You understand the following:<br/><br/>

                    1. Business sponsors are only allowed to occupy one (1) exclusive subcategory per hometown.  
                    All exclusive subcategories are listed at <NavLink to='/be-exclusive'>whatsuptexas.com/be-exclusive</NavLink><br/><br/>

                    2. What's Up Keller? LLC reserves the right to add, remove, and (or) modify subcategories on 
                    the exclusive subcategories list at any time.<br/><br/>

                    3. What's Up Keller? LLC reserves the right to change a business sponsor's exclusive subcategory at any time.
                    </p>
                    <h5>Cancellation</h5>
                    <p>
                    You understand that What's Up Texas? business sponsors may cancel their sponsorship at anytime by 
                    providing written notification (email is acceptable.)  What's Up Keller? LLC may cancel the 
                    sponsorship of a What's Up Texas? business sponsor at anytime by providing written notification 
                    (email is acceptable.)
                    </p>
                    <h5>Data</h5>
                    <p>
                    You understand that all What's Up Texas? website, mobile app, loyalty pass, and newsletter data 
                    is the sole property of What's Up Keller? LLC.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SponsorTerms